import * as React from "react";
const PlayCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#6F6F6F"
      d="M9 2.25a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5Zm-1.125 9v-4.5L11.25 9l-3.375 2.25Z"
      opacity={0.2}
    />
    <path
      stroke="#6F6F6F"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M9 15.75a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z"
    />
    <path
      stroke="#6F6F6F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.25 9 7.875 6.75v4.5L11.25 9Z"
    />
  </svg>
);
export default PlayCircle;
