import handleError from "./handle-error"
import api from "../api";
import axios from "../utils/axios.instance";

export const addCandidate = async (payload) => {
  try {
    const { data } = await axios.post(api.addCandidate, payload);

    return data;
  } catch (err) {
    return handleError(err);
  }
}

export const getCandidates = async (payload) => {
  try {
    const { data } = await axios.post(api.getCandidates, payload);

    return data;
  } catch (err) {
    return handleError(err);
  }
}
