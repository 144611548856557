import React, { useEffect } from "react";
import Section from "./Section/Section";
import Question from "./Question/Question";
import { AddSectionButton, QuestionContainer } from "./CreateQuestion.styles";
import useCreateQuestion from "./CreateQuestion.controller";
import {
  SavedSection,
  SavedSectionRowView,
  SavedSectionView,
  Title,
} from "./Section/Section.styles";
import PencilLine from "../../assets/svg/PencilLine";
import Trash from "../../assets/svg/Trash";
import SingleQuestion from "../../assets/svg/SingleQuestion";
import MultiQuestion from "../../assets/svg/MultiQuestion";
import ChevronDown from "../../assets/svg/ChevronDown";
import Tooltip from "../ToolTip/ToolTip";

const CreateQuestion = ({ testId, onAssessmentChange }) => {
  const {
    onSectionTitleSave,
    assessment,
    onChangeQuestionType,
    addAndRemoveOptions,
    handleQuestionSave,
    addNewQuestion,
    onEditTitle,
    onDeleteSection,
    onEditQuestion,
    onDeleteQuestion,
    addNewSection,
    isLoading,
  } = useCreateQuestion({ testId });

  useEffect(() => {
    onAssessmentChange(assessment.length > 1);
  }, [assessment, onAssessmentChange]);

  return (
    <div className="overflow-y-scroll no-scrollbar">
      {assessment?.map((a, index) => (
        <div key={a.id} className={`${index > 0 && "mt-4"}`}>
          <Title>{`Section ${index + 1}`}</Title>
          {!a?.sectionSaved ? (
            <Section
              section={a.section}
              sectionSaved={a.sectionSaved}
              onSave={(title) =>
                onSectionTitleSave({ ...title, sectionIndex: index })
              }
            />
          ) : (
            <SavedSectionRowView>
              <SavedSectionView>
                <div className="flex justify-between flex-1">
                  <SavedSection>{a.section}</SavedSection>
                  <ChevronDown className={"w-5 h-5"} fill={"#2e2e2e"} />
                </div>
              </SavedSectionView>
              <button onClick={() => onEditTitle({ sectionIndex: index })}>
                <PencilLine />
              </button>
              <button onClick={() => onDeleteSection({ sectionIndex: index })}>
                <Trash />
              </button>
            </SavedSectionRowView>
          )}

          {a?.sectionEditing && a?.questions?.length > 0 && (
            <QuestionContainer>
              {a?.questions?.map((question, qIndex) => (
                <Question
                  key={question.id}
                  question={question}
                  count={qIndex + 1}
                  totalCount={a?.questions?.length}
                  isLoading={isLoading}
                  onChangeQuestionType={(type) =>
                    onChangeQuestionType({
                      questionIndex: qIndex,
                      sectionIndex: index,
                      questionType: type,
                    })
                  }
                  addAndRemoveOptions={({ choiceIndex, type }) =>
                    addAndRemoveOptions({
                      sectionIndex: index,
                      questionIndex: qIndex,
                      index: choiceIndex,
                      type: type,
                    })
                  }
                  onSave={(localQuestion) =>
                    handleQuestionSave({
                      localQuestion,
                      questionIndex: qIndex,
                      sectionId: a.sectionId,
                      sectionIndex: index,
                    })
                  }
                  addNewQuestion={() =>
                    addNewQuestion({
                      sectionIndex: index,
                      questionIndex: qIndex,
                    })
                  }
                  onEditQuestion={() =>
                    onEditQuestion({
                      sectionIndex: index,
                      questionIndex: qIndex,
                    })
                  }
                  onDeleteQuestion={() =>
                    onDeleteQuestion({
                      sectionIndex: index,
                      questionIndex: qIndex,
                    })
                  }
                />
              ))}
            </QuestionContainer>
          )}
          {a?.sectionEditing && a?.section && a?.questions.length > 0 && (
            <div className="w-full border-t border-dashed border-light-grey mt-6" />
          )}
          {a?.sectionEditing && a?.section && !a?.questionType && (
            <div className={"pt-6 w-max"}>
              <span className="font-montserrat text-light-black font-normal text-xs pb-1.5">
                Add questions to this section
              </span>
              <div className="flex gap-[29px] bg-[#F7F7F7] py-1.5 px-5 rounded">
                <Tooltip message="Add Question">
                  <button
                    style={{
                      border: "1px solid #BABABA",
                    }}
                    className="rounded-lg px-2 py-1 flex items-center bg-white"
                    onClick={() =>
                      addNewQuestion({
                        sectionIndex: index,
                        questionType: "single-answer",
                      })
                    }
                  >
                    +
                    <SingleQuestion />
                  </button>
                </Tooltip>
                <Tooltip message={`Add Multiple\nChoice Question`}>
                  <button
                    style={{
                      border: "1px solid #BABABA",
                    }}
                    className="rounded-lg px-2 py-1 flex items-center bg-white"
                    onClick={() =>
                      addNewQuestion({
                        sectionIndex: index,
                        questionType: "multiple-choice",
                      })
                    }
                  >
                    +
                    <MultiQuestion />
                  </button>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      ))}
      <AddSectionButton onClick={addNewSection}>Add Section</AddSectionButton>
    </div>
  );
};

export default CreateQuestion;
