import { useSnackbar } from "notistack";
import { useState } from "react";
// import { useSelector } from "react-redux";
import {
  checkBoxQuestion,
  multipleChoice,
  singleAnswer,
} from "./CreateQuestion.const";
// import {
//   createSection,
//   createQuestion,
//   deleteSection,
//   deleteQuestion,
// } from "endpoints/test";

const useCreateQuestion = ({ testId }) => {
  const [assessment, setAssessment] = useState([
    { id: 1, section: undefined, sectionSaved: false, sectionEditing: true },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const { token } = useSelector((state) => state.auth);

  const onSectionTitleSave = async ({ sectionTitle, sectionIndex }) => {
    try {
      const payload = new FormData();
      payload.append("title", sectionTitle);
      payload.append("testId", testId);

      const existingSection = assessment?.[sectionIndex];
      if (existingSection?.sectionId) {
        payload.append("id", existingSection?.sectionId);
      }

      // const { data } = await createSection(payload, token);
      const tempAssessment = [...assessment];
      tempAssessment[sectionIndex] = {
        ...tempAssessment[sectionIndex],
        section: sectionTitle,
        sectionSaved: true,
        sectionEditing: true,
        sectionId: tempAssessment.length + 1,
        questions: [],
      };

      const updatedAssessment = tempAssessment.map((section, index) =>
        index === sectionIndex
          ? { ...section, sectionEditing: true }
          : { ...section, sectionEditing: false }
      );
      setAssessment(updatedAssessment);
    } catch (error) {
      enqueueSnackbar("Oops! something error occoured!", {
        variant: "error",
      });
    }
  };

  const onEditTitle = ({ sectionIndex }) => {
    const tempAssessment = [...assessment];
    tempAssessment[sectionIndex] = {
      ...tempAssessment[sectionIndex],
      sectionSaved: false,
      sectionEditing: true,
    };
    const updatedAssessment = tempAssessment.map((section, index) =>
      index === sectionIndex
        ? { ...section, sectionEditing: true }
        : { ...section, sectionEditing: false }
    );
    setAssessment(updatedAssessment);
  };

  const addNewSection = () => {
    let tempData = assessment.map((section) => ({
      ...section,
      sectionEditing: false,
    }));

    const maxId = tempData.reduce((max, { id }) => Math.max(max, id), 0);

    tempData.push({
      id: maxId + 1,
      section: undefined,
      sectionSaved: false,
      sectionEditing: true,
    });
    setAssessment(tempData);
  };

  const onDeleteSection = async ({ sectionIndex }) => {
    try {
      const sectionId = assessment[sectionIndex]?.sectionId;
      if (sectionId) {
        // await deleteSection(sectionId, token);
      }

      const tempAssessment = [...assessment];
      tempAssessment.splice(sectionIndex, 1);

      if (!tempAssessment.length) {
        setAssessment([
          {
            id: 1,
            section: undefined,
            sectionSaved: false,
            sectionEditing: true,
          },
        ]);
      } else {
        setAssessment(tempAssessment);
      }
    } catch (error) {
      enqueueSnackbar("Oops! something error occurred!", {
        variant: "error",
      });
    }
  };

  const onChangeQuestionType = ({
    sectionIndex,
    questionIndex,
    questionType,
  }) => {
    const tempData = [...assessment];
    let question;
    if (questionType === "multiple-choice") {
      question = multipleChoice;
    } else if (questionType === "check-box") {
      question = checkBoxQuestion;
    } else {
      question = singleAnswer;
    }
    if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
      tempData[sectionIndex].questions[questionIndex] = question;
    }
    setAssessment(tempData);
  };

  const handleQuestionSave = async ({
    localQuestion,
    sectionId,
    sectionIndex,
    questionIndex,
  }) => {
    try {
      setIsLoading(true);
      let question = assessment[sectionIndex].questions[questionIndex];
      question.question = localQuestion.question;
      let payload = new FormData();

      if (localQuestion.editorContent) {
        payload.append(
          "editorContent",
          JSON.stringify(localQuestion.editorContent)
        );
        question.editorContent = localQuestion.editorContent;
      } else {
        question.answer = localQuestion.answer;
        question.choices = question.choices?.map((x) => ({
          ...x,
          option: localQuestion[`option ${x.id}`],
        }));
        question?.choices.flatMap((choice, index) => {
          payload.append(`answer${index + 1}`, choice?.option);
        });
        payload.append("bestAnswer", parseInt(question.answer));
      }
      payload.append("type", question?.type);
      payload.append("questionTitle", question?.question);
      payload.append("testType", "wholeCourse");
      payload.append("testId", testId);
      payload.append("testSectionId", sectionId);
      if (question?.questionId) {
        payload.append("id", question?.questionId);
      }
      // const { data } = await createQuestion(payload, token);
      let tempData = assessment;

      if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
        tempData[sectionIndex].questions[questionIndex] = {
          ...question,
          saved: question?.questionId ? false : true,
          editing: question?.questionId ? false : true,
          questionId: data.data.id,
        };
      }
      if (question?.questionId) {
        const lastQuestionIndex = assessment[sectionIndex].questions.length - 1;
        assessment[sectionIndex].questions[lastQuestionIndex] = {
          ...assessment[sectionIndex].questions[lastQuestionIndex],
          editing: true,
          saved: false,
        };
      }
      setAssessment([...tempData]);
      enqueueSnackbar(data.message, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Oops! something error occoured!", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addAndRemoveOptions = ({
    type,
    sectionIndex,
    questionIndex,
    index,
  }) => {
    let tempArray = assessment;
    if (
      tempArray &&
      tempArray[sectionIndex] &&
      tempArray[sectionIndex].questions &&
      tempArray[sectionIndex].questions[questionIndex] &&
      tempArray[sectionIndex].questions[questionIndex].choices
    ) {
      if (type === "add") {
        tempArray[sectionIndex].questions[questionIndex].choices.splice(
          index,
          0,
          {
            id:
              tempArray[sectionIndex].questions[questionIndex].choices.length +
              1,
            option: undefined,
          }
        );
      } else {
        tempArray[sectionIndex].questions[questionIndex].choices.splice(
          index,
          1
        );
      }
    }
    setAssessment([...tempArray]);
  };

  const addNewQuestion = ({ sectionIndex, questionType }) => {
    let tempData = [...assessment];
    if (tempData[sectionIndex]) {
      tempData[sectionIndex].questions = tempData[sectionIndex].questions.map(
        (q) => ({ ...q, editing: false })
      );

      const maxId = tempData[sectionIndex].questions.reduce(
        (max, { id }) => Math.max(max, id),
        0
      );

      let newQuestion;
      if (questionType === "multiple-choice") {
        newQuestion = {
          id: maxId + 1,
          ...multipleChoice,
          editing: true,
        };
      } else {
        newQuestion = {
          id: maxId + 1,
          ...singleAnswer,
          editing: true,
        };
      }
      tempData[sectionIndex].questions.push(newQuestion);
      setAssessment([...tempData]);
    }
  };

  const onEditQuestion = ({ sectionIndex, questionIndex }) => {
    let tempData = [...assessment];
    if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
      tempData[sectionIndex].questions = tempData[sectionIndex].questions.map(
        (question, index) => {
          return {
            ...question,
            editing: index === questionIndex,
          };
        }
      );
    }
    setAssessment([...tempData]);
  };

  const onDeleteQuestion = async ({ sectionIndex, questionIndex }) => {
    try {
      let tempData = [...assessment];
      if (tempData[sectionIndex] && tempData[sectionIndex].questions) {
        const questionToBeRemoved =
          tempData[sectionIndex].questions[questionIndex];
        if (questionToBeRemoved.questionId) {
          // await deleteQuestion(questionToBeRemoved.questionId, token);
        }
        tempData[sectionIndex].questions.splice(questionIndex, 1);
        setAssessment(tempData);
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      enqueueSnackbar(
        "Oops! Something went wrong while deleting the question!",
        {
          variant: "error",
        }
      );
    }
  };

  return {
    onSectionTitleSave,
    assessment,
    onChangeQuestionType,
    addAndRemoveOptions,
    handleQuestionSave,
    addNewQuestion,
    onEditTitle,
    onDeleteSection,
    onEditQuestion,
    onDeleteQuestion,
    addNewSection,
    isLoading,
  };
};

export default useCreateQuestion;
