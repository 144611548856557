import { useNavigate } from "react-router-dom";
import ChevronDown from "../../../assets/svg/ChevronDown";
import PlusIcon from "../../../assets/svg/PlusIcon";
import SearchIcon from "../../../assets/svg/SearchIcon";
import { AssessmentSetupData, LiveAssessmentsData } from "./dummyData";
import { useCallback } from "react";

const LiveAssessmentCard = ({ item, onClickAssessment }) => {
  return (
    <div
      className="flex items-center py-3.5 border rounded-md bg-[#FAFAFA] pl-3  hover:bg-white cursor-pointer mb-3"
      onClick={() => onClickAssessment && onClickAssessment(item)}
    >
      <label className="w-1/4 font-medium text-xs text-primary">
        {item.refId}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {item.title}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-semibold text-xs text-primary"
      >
        Assessments taken ({item.assessmentsTaken})
        <label
          className={`${
            item.newAssessments > 0 ? "text-secondary" : "text-primary"
          } font-medium text-xs pl-2`}
        >
          New ({item.newAssessments})
        </label>
      </label>
      <label
        style={{ width: "20%" }}
        className="italic font-medium text-xs text-primary"
      >
        {item.time}
        <label className="font-medium text-xs text-primary pl-2">
          {item.date}
        </label>
      </label>
    </div>
  );
};

const AssessmentSetupCard = ({ item, onClickAssessment }) => {
  return (
    <div
      className="flex items-center py-3.5 border rounded-md bg-[#FAFAFA] pl-3  hover:bg-white cursor-pointer mb-3"
      onClick={() => onClickAssessment && onClickAssessment(item)}
    >
      <label className="w-1/4 font-medium text-xs text-primary">
        {item.refId}
      </label>
      <label
        style={{ width: "30%" }}
        className="font-medium text-xs text-primary"
      >
        {item.title}
      </label>
      <label
        style={{ width: "30%" }}
        className={`${
          item.status === "Assessment Live" ? "text-secondary" : "text-primary"
        } font-semibold text-xs`}
      >
        {item.status}
      </label>
      <label
        style={{ width: "20%" }}
        className="italic font-medium text-xs text-primary"
      >
        {item.time}
        <label className="font-medium text-xs text-primary pl-2">
          {item.date}
        </label>
      </label>
    </div>
  );
};

const AssessmentsSection = ({ activeTab }) => {
  const navigate = useNavigate();

  const onClickAssessment = useCallback(
    (assessment) => {
      navigate("assessment-details", {
        state: assessment,
      });
    },
    [navigate]
  );

  return (
    <>
      {/* Search and filter section */}
      <div className="flex items-center justify-between w-full mt-6">
        <div className="flex items-center gap-8">
          {/* Input container */}
          <div className="flex rounded-lg gap-3 items-center justify-between bg-light-white px-3 py-1.5">
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-2">
              <label className="text-primary text-sm font-medium">
                Search by
              </label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
            <input
              className="bg-white text-xs font-medium text-light-black outline-none px-3 py-2 rounded-lg w-80"
              placeholder="Search ..."
            />
            <SearchIcon className={"w-6 h-6"} fill={"#2e2e2e"} />
          </div>
          {activeTab !== "Live Assessments" && (
            <button className="flex items-center justify-center border bg-white rounded-md px-4 py-1 gap-4">
              <label className="text-primary text-sm font-medium">Status</label>
              <ChevronDown className={"w-4 h-4"} fill={"#2e2e2e"} />
            </button>
          )}
        </div>

        <button
          onClick={() => navigate("/my-assessment/create-assessment")}
          className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-sm"
        >
          <PlusIcon className={"w-4 h-4"} fill={"white"} />
          Assessment
        </button>
      </div>

      {/* ScrollArea  */}
      <div className="flex flex-1 flex-col pt-5 max-h-fit overflow-y-hidden">
        <div className="flex items-center pb-3 pl-3">
          <label className="w-1/4 flex font-semibold text-xs text-primary">
            Reference
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Title
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Status
          </label>
          <div style={{ width: "20%" }} />
        </div>
        <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
          {activeTab === "Live Assessments" ? (
            <>
              {LiveAssessmentsData.map((item) => {
                return (
                  <LiveAssessmentCard
                    key={item}
                    item={item}
                    onClickAssessment={onClickAssessment}
                  />
                );
              })}
            </>
          ) : (
            <>
              {AssessmentSetupData.map((item) => {
                return (
                  <AssessmentSetupCard
                    key={item}
                    item={item}
                    onClickAssessment={onClickAssessment}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AssessmentsSection;
