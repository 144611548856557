import { refreshTokenService } from '../endpoints/auth.service';
import store from '../store/index';
const { dispatch } = store;

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const setup = (axios) => {
    const processQueue = (error, token = null) => {
        failedQueue.forEach((prom) => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const originalRequest = error.config;

            if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== 'auth/login' && originalRequest.url !== 'auth/token') {
                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    })
                        .then((token) => {
                            window.alert(token)
                            originalRequest.headers['accessToken'] = token;
                            return axios(originalRequest);
                        })
                        .catch((err) => {
                            return Promise.reject(err);
                        });
                }

                originalRequest._retry = true;
                isRefreshing = true;

                const refreshTokenStorage = window.localStorage.getItem('refreshToken');
                return new Promise(function (resolve, reject) {
                    dispatch(refreshTokenService(refreshTokenStorage))
                        .then((newToken) => {
                            processQueue(null, newToken);
                            resolve(axios(originalRequest));
                        })
                        .catch((err) => {
                            processQueue(err, null);
                            reject(err);
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                });
            }

            return Promise.reject(error);
        }
    );
};
export default setup;
