import YooptaEditor, { createYooptaEditor } from "@yoopta/editor";

import Paragraph from "@yoopta/paragraph";
import Blockquote from "@yoopta/blockquote";
import Embed from "@yoopta/embed";
import Link from "@yoopta/link";
import Callout from "@yoopta/callout";
import { NumberedList, BulletedList, TodoList } from "@yoopta/lists";
import {
  Bold,
  Italic,
  CodeMark,
  Underline,
  Strike,
  Highlight,
} from "@yoopta/marks";
import { HeadingOne, HeadingThree, HeadingTwo } from "@yoopta/headings";
import Code from "@yoopta/code";
import ActionMenuList, {
  DefaultActionMenuRender,
} from "@yoopta/action-menu-list";
import Toolbar, { DefaultToolbarRender } from "@yoopta/toolbar";
import LinkTool, { DefaultLinkToolRender } from "@yoopta/link-tool";

// import { uploadToCloudinary } from '@/utils/cloudinary';
import { useEffect, useMemo, useRef } from "react";
import Accordion from "@yoopta/accordion";

const plugins = [
  Paragraph,
  Accordion,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Blockquote,
  Callout,
  NumberedList,
  BulletedList,
  TodoList,
  Code,
  Link,
  Embed,
  // Image.extend({
  //   options: {
  //     async onUpload(file) {
  //       // const data = await uploadToCloudinary(file, 'image');

  //       // return {
  //       //   src: data.secure_url,
  //       //   alt: 'cloudinary',
  //       //   sizes: {
  //       //     width: data.width,
  //       //     height: data.height,
  //       //   },
  //       // };
  //     },
  //   },
  // }),
  // Video.extend({
  //   options: {
  //     onUpload: async (file) => {
  //       // const data = await uploadToCloudinary(file, 'video');
  //       // return {
  //       //   src: data.secure_url,
  //       //   alt: 'cloudinary',
  //       //   sizes: {
  //       //     width: data.width,
  //       //     height: data.height,
  //       //   },
  //       // };
  //     },
  //   },
  // }),
  // File.extend({
  //   options: {
  //     onUpload: async (file) => {
  //       // const response = await uploadToCloudinary(file, 'auto');
  //       // return { src: response.url };
  //     },
  //   },
  // }),
];

const TOOLS = {
  ActionMenu: {
    render: DefaultActionMenuRender,
    tool: ActionMenuList,
  },
  Toolbar: {
    render: DefaultToolbarRender,
    tool: Toolbar,
  },
  LinkTool: {
    render: DefaultLinkToolRender,
    tool: LinkTool,
  },
};

const MARKS = [Bold, Italic, CodeMark, Underline, Strike, Highlight];

const CustomEditor = ({ readOnly = false, onChangeContent, value }) => {
  const editor = useMemo(() => createYooptaEditor(), [readOnly]);
  const selectionRef = useRef(null);

  function handleChange(value) {
    onChangeContent(value);
  }
  useEffect(() => {
    editor.on("change", handleChange);
    return () => {
      editor.off("change", handleChange);
    };
  }, [editor]);

  return (
    <div
      key={readOnly}
      ref={selectionRef}
      className="pl-12 pr-10 rounded-lg pb-0"
    >
      <YooptaEditor
        width={"100%"}
        editor={editor}
        plugins={plugins}
        readOnly={readOnly}
        tools={TOOLS}
        marks={MARKS}
        selectionBoxRoot={selectionRef}
        value={value}
        autoFocus={false}
      />
    </div>
  );
};

export default CustomEditor;

// import YooptaEditor, { createYooptaEditor } from "@yoopta/editor";
// import { Bold, Italic, CodeMark, Underline, Strike, Highlight } from '@yoopta/marks';
// import Paragraph from '@yoopta/paragraph';
// import Blockquote from '@yoopta/blockquote';
// import Code from '@yoopta/code';
// import Toolbar, { DefaultToolbarRender } from '@yoopta/toolbar';
// import { useMemo } from "react";
// import LinkTool, { DefaultLinkToolRender } from "@yoopta/link-tool";
// import ActionMenuList, { DefaultActionMenuRender } from "@yoopta/action-menu-list";

// const CustomEditor = () => {
//     const editor = useMemo(() => createYooptaEditor(), []);
//     const plugins = [
//       Paragraph,
//       Blockquote,
//       Code
//     ];
//     const MARKS = [Bold, Italic, CodeMark, Underline, Strike, Highlight];
//     const TOOLS = {
//         Toolbar: {
//           tool: Toolbar,
//           render: DefaultToolbarRender,
//         },
//         ActionMenu: {
//           render: DefaultActionMenuRender,
//           tool: ActionMenuList,
//         },
//         LinkTool: {
//           tool: LinkTool,
//           render: DefaultLinkToolRender,
//         },
//       };

//     return (
//       <div className="flex pl-10 bg-gray-100 rounded-lg h-max">
//         <YooptaEditor
//           editor={editor}
//           plugins={plugins}
//           tools={TOOLS}
//           marks={MARKS}
//         />
//       </div>
//     );
// }

// export default CustomEditor
