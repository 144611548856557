import { useEffect, useState } from "react";
import ChevronRightIcon from "../../../assets/svg/ChevronRightIcon";
import ClipboardIcon from "../../../assets/svg/ClipboardIcon";
import DoubleTick from "../../../assets/svg/DoubleTick";
import PlayCircle from "../../../assets/svg/PlayCircle";
import StackIcon from "../../../assets/svg/StackIcon";
import CreateQuestion from "../../../components/CreateQuestion";
import Divider from "../../../components/Divider/Divider";
import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput.jsx";
import { useSnackbar } from "notistack";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";
import { levelSelect } from "../../../utils/constants.js";
import { colors } from "../../../utils/theme.js";

const selectStyle = {
  MozAppearance: "none",
  WebkitAppearance: "none",
  appearance: "none",
  backgroundImage: `url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20fill%3D%22none%22%20viewBox%3D%220%200%2032%2032%22%20stroke-width%3D%220%22%20stroke%3D%22%232e2e2e%22%20class%3D%22custom-arrow%22%3E%3Cpath%20d%3D%22M9.3335%2013.333L16.0002%2019.9997L22.6668%2013.333%22%20stroke%3D%22%232e2e2e%22%20stroke-opacity%3D%221%22%20stroke-width%3D%224%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20/%3E%3C/svg%3E')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right .7em center",
  backgroundSize: "16px auto",
  paddingRight: "2.5em",
};

const FormAssessmentDetails = ({ data, setData }) => {
  // const { token } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    register,
    formState: { errors, isValid, isDirty },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: {
      title: data?.assessmentDetails?.title,
      description: data?.assessmentDetails?.description,
      level: data?.assessmentDetails?.level,
    },
  });

  const [isSaved, setIsSaved] = useState(false);

  const [shareCompanyLink, setShareCompanyLink] = useState(
    data?.assessmentDetails?.shareCompanyLink ?? false
  );

  const handleNext = async (formData) => {
    // try {
    // const payload = new FormData();
    // payload.append("title", formData?.title);
    // payload.append("level", formData?.level);
    // payload.append("description", formData?.description);

    // const { data } = await createTest(payload, token);

    // if (data?.success) {
    setData((prev) => {
      return {
        ...prev,
        assessmentDetails: {
          ...prev?.assessmentDetails,
          title: formData?.title,
          description: formData?.description,
          isCompleted: true,
          // shareCompanyLink,
          id: data?.data?.id,
        },
      };
    });
    enqueueSnackbar(
      data?.testId ? "Test updated successfully" : "Test created successfully",
      {
        variant: "success",
      }
    );
    // }
    // } catch (error) {
    //   if (error.response.status == 422 || error.response.status == 400) {
    //     const errors = error.response.data.err;
    //     const errorsArr = errors.split(",");
    //     errorsArr?.map((item, index) => {
    //       enqueueSnackbar(item, { variant: "error" });
    //     });
    //     return;
    //   }
    //   enqueueSnackbar("Oops! something error occoured!", {
    //     variant: "error",
    //   });
    // }
  };

  return (
    <div className="flex flex-col">
      <span className="font-montserrat font-semibold text-primary">
        Create Assessment
      </span>
      <div className="flex items-center justify-between w-full font-montserrat text-sm text-light-text mt-6 mb-10">
        <span className="font-montserrat font-medium text-black text-sm">
          Want to share link with the Company to create assessment questions?
        </span>
        <CustomCheckbox
          defaultValue={shareCompanyLink}
          onChange={(value) => setShareCompanyLink(value)}
        />
      </div>
      <span className="font-montserrat font-semibold text-xs text-primary">
        Assessment Reference : CM12345
      </span>
      {/* Form container */}
      <div className="flex flex-col items-center mt-7">
        <div className="flex w-full gap-4">
          <CustomInput
            label={"Enter Assessment Title"}
            placeholder={"Assessment Title"}
            bgColor={isSaved ? colors.Border : "bg-transparent"}
            inActiveOutlineColor="bg-[#E9EAF0]"
            register={{
              ...register("title", {
                required: "This field is required.",
              }),
            }}
            errors={errors["title"]}
            disabled={isSaved}
          />
          <div className="flex flex-col w-full">
            <span className="font-montserrat text-primary font-medium text-xs ">
              Assessment Level
            </span>
            <Controller
              name="level"
              control={control}
              render={({ field }) => (
                <select
                  {...field}
                  style={selectStyle}
                  disabled={isSaved}
                  className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs mt-2 flex items-center justify-between border-2 border-[#E9EAF0] h-10 cursor-pointer ${
                    isSaved ? `bg-[${colors.Border}]` : "bg-transparent"
                  }`}
                >
                  <option value="" disabled>
                    Select...
                  </option>
                  {levelSelect?.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            />
          </div>
        </div>

        <div className="w-full mt-3">
          <CustomInput
            label={"Enter Assessment Description"}
            placeholder={"Description"}
            bgColor={isSaved ? colors.Border : "bg-transparent"}
            inActiveOutlineColor="bg-[#E9EAF0]"
            isTextArea={true}
            register={{
              ...register("description", {
                required: "This field is required.",
              }),
            }}
            disabled={isSaved}
            errors={errors["description"]}
          />
        </div>

        <button
          disabled={!isValid}
          style={{
            border: "1px solid #2e2e2e",
          }}
          className={`flex items-center justify-center rounded-lg py-1 px-3 bg-black w-max  ml-auto mt-8 mb-[62px] ${
            isValid ? "opacity-100" : "opacity-50"
          }`}
          onClick={() => setIsSaved(!isSaved)}
        >
          <span className="font-montserrat text-white text-sm font-semibold">
            {isSaved ? "Edit" : "Save"}
          </span>
        </button>

        <button
          disabled={!isValid}
          style={{
            border: "1px solid #2e2e2e",
          }}
          className={`flex items-center justify-center rounded-lg py-1 px-3 bg-white w-max ml-auto ${
            isValid ? "opacity-100" : "opacity-50"
          }`}
          type="submit"
          onClick={handleSubmit(handleNext)}
        >
          <span className="font-montserrat text-black text-sm font-semibold">
            Next
          </span>
        </button>
      </div>
    </div>
  );
};

const RenderQuestions = () => {
  const [isAssessmentAvailable, setIsAssessmentAvailable] = useState(false);

  const handleAssessmentChange = (hasAssessment) => {
    setIsAssessmentAvailable(hasAssessment);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-[18px]">
        <span className="font-montserrat font-semibold text-base leading-5">
          Create Questions
        </span>
        <span className="font-montserrat font-semibold text-xs leading-5">
          Assessment Reference : CM12345
        </span>
        {isAssessmentAvailable && (
          <button
            onClick={() => navigate("/contacts/add-candidate")}
            className="flex items-center justify-center border bg-green-600 rounded-md px-2 py-1 gap-1 text-white font-semibold text-xs"
          >
            Save Assessment
          </button>
        )}
      </div>
      <Divider className="bg-light-grey mb-10" />
      <CreateQuestion onAssessmentChange={handleAssessmentChange} />
    </div>
  );
};

const RenderBody = ({ step, data, setData }) => {
  switch (step) {
    case 0:
      return <FormAssessmentDetails data={data} setData={setData} />;
    case 1:
      return (
        <div className="flex flex-col h-full">
          <RenderQuestions />
        </div>
      );
    case 2:
      return <div></div>;
    default:
      break;
  }
};

const CreateAssessment = () => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    assessmentDetails: {
      title: "",
      level: "",
      description: "",
      // shareCompanyLink: false,
      isCompleted: false,
    },
    companyDetails: {
      name: "",
      email: "",
      refNumber: "",
      isCompleted: false,
    },
    shareLink: {
      link: "",
      title: "",
      isCompleted: false,
    },
  });

  useEffect(() => {
    if (data?.assessmentDetails?.isCompleted) {
      setStep(1);
    }
  }, [data]);

  return (
    <div className="flex flex-row w-full relative laptop-small-screen gap-7">
      {/* Body Content */}
      <div className="flex w-full gap-4">
        {/* Left container */}
        <div className="w-[25%] ">
          <div className="flex flex-col gap-5 h-full">
            <div className="flex flex-col bg-white  shadow-nShadow pl-4 pr-8 py-6 rounded-lg gap-3">
              <button
                style={{
                  border: "1px solid #BABABA",
                }}
                className="rounded-lg py-1"
              >
                <span className="font-montserrat font-medium text-sm">
                  Preview
                </span>
              </button>
              <button
                style={{
                  border: "1px solid #BABABA",
                }}
                className="rounded-lg py-1"
              >
                <span className="font-montserrat font-medium text-sm">
                  Save as Draft
                </span>
              </button>
              <button
                style={{
                  border: "1px solid #BABABA",
                }}
                className="rounded-lg py-1"
              >
                <span className="font-montserrat font-medium text-sm">
                  Share Link
                </span>
              </button>
            </div>
            {/* Assessment Progress */}

            <div className="w-full h-full flex flex-col bg-white pb-3 px-4 pt-[18px] shadow-nShadow rounded-lg relative pb-16">
              <span className="font-montserrat font-semibold text-base">
                Create Assessment
              </span>
              <div className="flex flex-col mt-2 gap-1">
                <div
                  className={`${
                    step == 0 ? "bg-[#F2F2F2]" : "bg-transparent"
                  } flex items-center justify-between rounded-xl p-2`}
                >
                  <div className="flex items-center justify-between gap-1">
                    <StackIcon className={"w-5 h-5"} />
                    <span className="font-montserrat font-medium text-sm">
                      Details
                    </span>
                  </div>
                  {data?.assessmentDetails?.isCompleted ? (
                    <DoubleTick className={"w-5 h-5"} fill={"#23BD33"} />
                  ) : (
                    <ChevronRightIcon className={"w-3 h-3"} fill="#2e2e2e" />
                  )}
                </div>
                <div
                  className={`${
                    step == 1 ? "bg-[#F2F2F2]" : "bg-transparent"
                  } ${
                    data?.assessmentDetails?.shareCompanyLink
                      ? "opacity-50"
                      : "opacity-100"
                  } flex items-center justify-between rounded-xl p-2`}
                >
                  <div className="flex items-center justify-between gap-1">
                    <ClipboardIcon className={"w-5 h-5"} />
                    <span className="font-montserrat font-medium text-sm">
                      Questions
                    </span>
                  </div>
                  <ChevronRightIcon className={"w-3 h-3"} fill="#2e2e2e" />
                </div>
                <div
                  className={`${
                    step == 2 ? "bg-[#F2F2F2]" : "bg-transparent"
                  } flex items-center justify-between rounded-xl p-2`}
                >
                  <div className="flex items-center justify-between gap-1">
                    <PlayCircle className={"w-5 h-5"} />
                    <span className="font-montserrat font-medium text-sm">
                      Review and Confirm
                    </span>
                  </div>
                  <ChevronRightIcon className={"w-3 h-3"} fill="#2e2e2e" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[70%]">
          <div className="shadow-nShadow bg-white flex flex-col flex-1 w-full h-full py-[30px] px-[20px] max-h-[88vh] rounded-lg">
            {/* <RenderQuestions /> */}
            <RenderBody data={data} setData={setData} step={step} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAssessment;
