export const userTypes = [
  {
    type: "admin",
    value: 1,
  },
  {
    type: "recruiter",
    value: 2,
  },
  {
    type: "candidate",
    value: 3,
  },
];

export const gender = [
  {
    label: "Male",
    value: 1,
  },
  {
    label: "Female",
    value: 2,
  },
  {
    label: "Others",
    value: 3,
  },
];

const levelSelect = [
  {
    label: "Beginner",
    value: 1,
  },
  {
    label: "Foundation",
    value: 2,
  },
  {
    label: "Intermediate",
    value: 3,
  },
  {
    label: "Expert",
    value: 4,
  },
  {
    label: "Elite",
    value: 5,
  },
];

const questionTypes = [
  {
    label: "Multiple Choice Question",
    value: "multiple-choice",
  },
  {
    label: "Check Box Question",
    value: "check-box",
  },
  {
    label: "Single Question/Answer",
    value: "single-answer",
  },
];

const sliderValues = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 9.7,
    label: "10",
  },
];

const skillsLastUsed = [
  {
    label: "Current",
    value: "current",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Last Year",
    value: "lastYear",
  },
  {
    label: "2+ years ago",
    value: "2yearsAgo",
  },
];
export { levelSelect, questionTypes, sliderValues, skillsLastUsed };
