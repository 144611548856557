import "twin.macro";
import tw, { styled } from "twin.macro";
import { colors } from "../../../utils/theme";

export const Container = tw.div`flex flex-col flex-1`;

export const Title = styled.span(() => [
  tw`font-montserrat font-medium text-xs leading-4`,
  `color:${colors.TextSecondary}`,
]);

export const ColumnContainer = tw.div`flex flex-col gap-y-2`;

export const CurrectAnsTitle = styled.span(() => [
  tw`font-montserrat font-semibold text-xs leading-4 mt-4`,
  `color:${colors.TextSecondary}`,
]);

export const RowQuestionContainer = tw.div`flex gap-4 items-center justify-between mb-2`;

export const RowContainer = tw.div`flex gap-4 mt-3`;

export const RadioContainer = tw.div`flex items-center gap-1.5 cursor-pointer`;

export const CurrectAnsText = styled.span(() => [
  tw`font-montserrat font-medium text-xs leading-4`,
  `color:${colors.TextSecondary}`,
]);

export const Select = tw.select`rounded-lg px-3 pr-5 font-montserrat font-medium text-xs flex items-center justify-between sm:w-3/6 xl:w-2/6 border-2 border-[#E9EAF0] h-10 cursor-pointer`;

export const OptionRowContainer = tw.div`flex items-center gap-2 pl-2`;

export const SavedQuestionRowView = tw.div`flex items-center gap-3`;

export const SavedQuestionView = tw.div`flex flex-1 min-h-9 items-center gap-3 py-1.5 px-2 bg-[#F5F5F5] rounded`;

export const SavedQuestion = styled.span(() => [
  tw`font-montserrat font-normal text-sm`,
  `color:${colors.PrimaryBlack}`,
]);

export const SavedQuestionIndex = styled.span(() => [
  tw`font-montserrat font-normal text-sm`,
  `color:${colors.PrimaryBlack}`,
]);

export const OptionNumberText = styled.span(() => [
  tw`font-montserrat font-medium text-xs text-primary`,
]);

export const RowButtonContainer = tw.div`flex gap-1 mt-6 justify-end`;
