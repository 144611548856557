import {
    REMOVE_TOKEN,
    LOGIN_USER,
    AUTH_USER_UPDATE,
    SET_AUTH_LOADING,
    LOGIN_TOKEN,
    SET_USER_DATA
} from '../actions/action.types';
const getRefreshToken = () => {
    localStorage.getItem('refreshToken');
};
const initialState = {
    logged_in: false,
    loading: true,
    authenticated: false,
    user: null,
    token: '',
    refreshToken: getRefreshToken
};

const authReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case REMOVE_TOKEN:
            localStorage.removeItem('refreshToken');
            return { ...state, ...initialState };

        case LOGIN_TOKEN:
            localStorage.setItem('refreshToken', payload.refreshToken);
            return state;

        case LOGIN_USER:
            let { user, token, refreshToken } = payload;
            localStorage.setItem('refreshToken', refreshToken);
            return {
                ...state,
                logged_in: true,
                loading: false,
                authenticated: true,
                user: user,
                token: token
            };

        case SET_USER_DATA:
            return {
                ...state,
                logged_in: true,
                loading: false,
                authenticated: true,
                user: { ...state.user, ...payload },
                token: payload?.token
            };

        case AUTH_USER_UPDATE:
            return {
                ...state,
                user: payload
            };
        case SET_AUTH_LOADING:
            // if((payload == false) && (window.location.pathname != "/" && window.location.pathname != "/register" && window.location.pathname != "/login")){
            //     window.location.href = "/login";
            // }
            return {
                ...state,
                loading: payload
            };
        default:
            return state;
    }
};

export default authReducer;
