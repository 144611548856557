import "./App.css";
import LandingPage from "./pages/LandingPage/LandingPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./pages/Layout/Layout";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/ResgisterPage";
import DashboardPage from "./pages/Dashboard/Dashboard";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import AddCandidatePage from "./pages/ContactsPage/CandidatesSection/AddCandidatePage/AddCandidatePage";
import EmailVerification from "./pages/EmailVerification/EmailVerification";
import MyAssessmentsPage from "./pages/MyAssessmentsPage/MyAssessmentsPage";
import CalendarsPage from "./pages/CalendarsPage/CalendarsPage";
import { useSelector } from "react-redux";
import CreateAssessment from "./pages/MyAssessmentsPage/CreateAssessmentSection/CreateAssessmentSection";
import AssessmentDetails from "./pages/MyAssessmentsPage/AssessmentDetails/AssessmentDetails";
import CandidateAssessments from "./pages/MyAssessmentsPage/CandidateAssessments/CandidateAssessments";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/email-verification",
        element: <EmailVerification />,
      },
      {
        path: "/register",
        element: <RegisterPage />,
      },
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/contacts",
        children: [
          {
            index: true,
            element: <ContactsPage />,
          },
          {
            path: "add-candidate",
            element: <AddCandidatePage />,
          },
        ],
      },
      {
        path: "/my-assessment",
        children: [
          {
            index: true,
            element: <MyAssessmentsPage />,
          },
          {
            path: "create-assessment",
            element: <CreateAssessment />,
          },
          {
            path: "assessment-details",
            element: <AssessmentDetails />,
          },
          {
            path: "candidate-Assessments",
            element: <CandidateAssessments />,
          },
        ],
      },
      {
        path: "/calendar",
        children: [
          {
            index: true,
            element: <CalendarsPage />,
          },
        ],
      },
      {
        path: "*",
        element: <DashboardPage />,
      },
    ],
  },
]);

function App() {
  const user = useSelector((state) => state.auth);
  console.log("user details", user);

  return <RouterProvider router={router} />;
}

export default App;
