import * as React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import es from "react-phone-input-2/lang/es.json";
import fr from "react-phone-input-2/lang/fr.json";
import ru from "react-phone-input-2/lang/ru.json";
import { Controller } from "react-hook-form";

const CustomPhoneNumberInput = ({ label, name, control, errors }) => {
    const localeCodes = { es, fr, ru };
    const lngCode = "us";

    return (
        <div className="flex flex-col w-full">
            {label && (
                <span className="font-montserrat text-primary font-medium text-xs mb-2">
                    {label}
                </span>
            )}
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={{
                    validate: (value) => {
                        return value.length >= 10 || "Invalid phone number";
                    },
                }}
                render={({ field }) => (
                    <PhoneInput
                        {...field}
                        country={lngCode}
                        placeholder="Enter your phone number"
                        onChange={field.onChange}
                        localization={localeCodes[lngCode]}
                        inputProps={{
                            name,
                            required: true,
                            autoFocus: true,
                        }}
                        isValid={(inputNumber, country) => {
                            return inputNumber.length === 10;
                        }}
                        className={`!w-full`}
                        inputStyle={{
                            width: "100%",
                            backgroundColor: 'white',
                            border: '1px solid gray'
                        }}
                    />
                )}
            />
            {errors?.message && (
                <span className="whitespace-pre text-xs mt-2 text-orange-700">
                    {errors?.message}
                </span>
            )}
        </div>
    );
};

export default CustomPhoneNumberInput;
