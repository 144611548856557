import { useEffect, useState } from "react";

const EmailSuccess = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 84 84" class={_class}>
            <path d="M13.1258 64.881H40.9508C41.5304 64.881 42.0008 64.4106 42.0008 63.831C42.0008 63.2514 41.5304 62.781 40.9508 62.781H13.1258C12.8796 62.781 12.6465 62.7306 12.427 62.6524L33.2874 39.166L36.01 41.6382C37.4186 42.9171 39.1847 43.5566 40.9508 43.5566C42.7169 43.5566 44.4835 42.9171 45.8916 41.6382L48.6147 39.166L50.1582 40.9037C50.3656 41.1374 50.6538 41.2565 50.9436 41.2565C51.192 41.2565 51.4408 41.1689 51.6408 40.9914C52.0745 40.6066 52.1139 39.9425 51.7285 39.5093L50.1698 37.7543L70.7571 19.0616C70.8291 19.2732 70.8768 19.4958 70.8768 19.7315V41.3222C70.8768 41.9018 71.3472 42.3722 71.9268 42.3722C72.5064 42.3722 72.9768 41.9018 72.9768 41.3222V19.731C72.9768 17.4152 71.0926 15.531 68.7768 15.531H13.1258C10.81 15.531 8.92578 17.4152 8.92578 19.731V60.681C8.92578 62.9973 10.81 64.881 13.1258 64.881ZM11.061 61.0286C11.0421 60.9152 11.0258 60.8002 11.0258 60.681V19.731C11.0258 19.4953 11.0736 19.2727 11.1455 19.0611L31.7323 37.7537L11.061 61.0286ZM68.7758 17.631C68.9086 17.631 69.0383 17.6462 69.1643 17.6704L44.4798 40.0837C42.4675 41.9107 39.4335 41.9102 37.4212 40.0837L12.7373 17.6704C12.8633 17.6468 12.993 17.631 13.1258 17.631H68.7758Z" fill="#BABABA" />
            <path d="M60.8992 41.1689C51.3463 41.1689 43.5742 48.941 43.5742 58.4939C43.5742 68.0468 51.3463 75.819 60.8992 75.819C70.4521 75.819 78.2242 68.0468 78.2242 58.4939C78.2242 48.941 70.4521 41.1689 60.8992 41.1689ZM60.8992 73.719C52.5039 73.719 45.6742 66.8892 45.6742 58.4939C45.6742 50.0992 52.5039 43.2689 60.8992 43.2689C69.2945 43.2689 76.1242 50.0992 76.1242 58.4939C76.1242 66.8887 69.2945 73.719 60.8992 73.719Z" fill="#BABABA" />
            <path d="M70.395 53.8135L59.8499 64.3586L52.9797 57.4885C52.5702 57.0784 51.9045 57.0784 51.495 57.4885C51.085 57.8985 51.085 58.5631 51.495 58.9737L59.1075 66.5862C59.3123 66.7915 59.5811 66.8933 59.8499 66.8933C60.1187 66.8933 60.3875 66.7909 60.5922 66.5862L71.8797 55.2987C72.2897 54.8887 72.2897 54.224 71.8797 53.8135C71.4702 53.404 70.8045 53.404 70.395 53.8135Z" fill="#BABABA" />
        </svg>
    )
};

export default EmailSuccess;