import "twin.macro";
import tw, { styled, css } from "twin.macro";
import { colors } from "../../../utils/theme";

export const Label = styled.label`
  ${tw`text-sm font-semibold`}
  color: ${colors.PrimaryBlack};
`;

export const CandidateRowText = styled.label`
  ${tw`text-xs font-medium font-montserrat`}
  color: ${colors.PrimaryBlack};
`;
