import { useForm } from "react-hook-form"
import CustomInput from "../../../../../components/CustomInput/CustomInput"
import CustomPhoneNumberInput from "../../../../../components/CustomPhoneNumberInput/CustomPhoneNumberInput"
import { useRef } from "react"
import regexList from "../../../../../utils/regexList"
import FolderIcon from "../../../../../assets/svg/FolderIcon"
import { addCandidate } from "../../../../../endpoints/candidate.service"
import { useSelector } from "react-redux"
import { useSnackbar } from "notistack"

const AboutSection = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector(state => state.auth);
    const filePickerRef = useRef()
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({

    })


    const savePersonalDetails = (data) => {
        const payload = {
            ...data,
            dob: new Date(data?.dob).toISOString(),
            countryCode: data?.phoneNo.substr(0, 2),
            phoneNo: parseInt(data?.phoneNo),
            gender: parseInt(data?.gender),
            type: user?.type === 2 ? 1 : 2
        }

        addCandidate(payload).then((res) => {
            if (res?.success) {
                enqueueSnackbar('Details added successfully', { variant: "success" });
            }
        }).catch((err) => {
            enqueueSnackbar(err, { variant: "error" });
        })
    }

    return <form onSubmit={handleSubmit(savePersonalDetails)} className="border rounded-lg px-4 py-4 mt-5">
        <div className="flex items-center justify-between">
            <label className="font-semibold text-sm">Personal Details</label>
            <button type="submit" className="font-semibold text-sm">Save</button>
        </div>
        {/* Form container */}
        <div className="flex itesm-center gap-5 mt-6">
            {/* Col 1 */}
            <div
                className="flex flex-col flex-1 gap-5"
            >
                <CustomInput
                    label={'First Name'}
                    placeholder={"Candidate's First Name"}
                    inActiveOutlineColor="border border-px"
                    bgColor={'bg-white'}
                    register={{
                        ...register("firstName", {
                            required: "This field is required.",
                        })
                    }}
                    errors={errors['firstName']}
                />

                <CustomPhoneNumberInput
                    label={"Phone Number"}
                    name={'phoneNo'}
                    control={control}
                    errors={errors}
                />

                <CustomInput
                    label={'Job Title'}
                    placeholder={'Ex: Sales Manager'}
                    inActiveOutlineColor="border border-px"
                    bgColor={'bg-white'}
                    register={{
                        ...register("jobTitle", {
                            required: "This field is required.",
                        })
                    }}
                    errors={errors['jobTitle']}
                />

                <CustomInput
                    label={"Gender"}
                    name={'gender'}
                    control={control}
                    inActiveOutlineColor="border border-px"
                    bgColor={'bg-white'}
                    errors={errors}
                    type="dropdown"
                    placeholder={'Select Gender'}
                    dropdownList={['Male', 'Female', 'Others']}
                    register={{
                        ...register("gender", {
                            required: "This field is required.",
                        })
                    }}
                />
            </div>
            {/* Col 2 */}
            <div
                className="flex flex-col flex-1 gap-5"
            >
                <CustomInput
                    label={'Last Name'}
                    placeholder={"Candidate's Last Name"}
                    inActiveOutlineColor="border border-px"
                    bgColor={'bg-white'}
                    register={{
                        ...register("lastName", {
                            required: "This field is required.",
                        })
                    }}
                    errors={errors['lastName']}
                />
                <CustomInput
                    label={'Email Address'}
                    placeholder={'abc@gmail.com'}
                    inActiveOutlineColor="border border-px"
                    bgColor={'bg-white'}
                    register={{
                        ...register("email", {
                            required: "This field is required.",
                            pattern: {
                                value: regexList.email,
                                message: "Please enter a valid email."
                            }
                        })
                    }}
                    errors={errors['email']}
                />
                <CustomInput
                    name={'dob'}
                    label={'Birth Date'}
                    placeholder={'16-11-1997'}
                    bgColor={'bg-white'}
                    inActiveOutlineColor="border border-px"
                    type="date"
                    control={control}
                    errors={errors}
                    register={{
                        ...register("dob", {
                            required: "This field is required.",
                        })
                    }}
                />
                <CustomInput
                    label={'Location'}
                    placeholder={'ELondon'}
                    inActiveOutlineColor="border border-px"
                    bgColor={'bg-white'}
                    register={{
                        ...register("location", {
                            required: "This field is required.",
                        })
                    }}
                    errors={errors['location']}
                />
            </div>
            {/* Col 3 */}
            <div
                className="flex flex-col flex-1 gap-5"
            >
                <div className="flex flex-col">
                    <span className="font-montserrat text-primary font-medium text-xs mb-2">Candidate CV</span>
                    <button
                        onClick={() => filePickerRef?.current?.click()}
                        className="flex items-center border rounded-lg border overflow-hidden pr-2">
                        <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">PDF</span>
                        <span className="text-xs font-medium my-auto px-3 text-[#6F6F6F] flex-1">Drop files here<span className="text-pink"> or browse</span></span>
                        <FolderIcon className={'w-8 h-8'} />
                    </button>
                    <input
                        ref={filePickerRef}
                        type='file'
                        className="hidden"
                    />
                </div>
            </div>
        </div>
    </form>

}

export default AboutSection