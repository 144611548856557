import { useState } from "react";
import { Controller } from "react-hook-form";

const CustomInput = ({
  label,
  placeholder,
  type = "text",
  renderRight,
  register,
  errors,
  containerStyle,
  bgColor,
  activeOutlineColor = "border-gray",
  inActiveOutlineColor = "border-transparent",
  isTextArea,
  // onFocus,
  // onBlur
  className,
  disabled,
  resize = true,
  name,
  control,
  dropdownList,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className={`flex flex-col w-full ${containerStyle}`}>
      {label && (
        <span className="font-montserrat text-primary font-medium text-xs mb-2">
          {label}
        </span>
      )}
      <div
        className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border ${
          bgColor ?? "bg-[#F7F7F7]"
        } ${
          isFocused ? activeOutlineColor : inActiveOutlineColor
        } ${className}`}
      >
        {isTextArea ? (
          resize ? (
            <textarea
              {...register}
              disabled={disabled}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="flex-1 bg-transparent outline-none"
              placeholder={placeholder}
              rows={5}
              type={type ?? "text"}
              style={{ lineHeight: "1.5" }}
            />
          ) : (
            <textarea
              {...register}
              disabled={disabled}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              className="flex-1 bg-transparent outline-none resize-none overflow-hidden"
              placeholder={placeholder}
              rows={1}
              type={type ?? "text"}
              style={{
                lineHeight: "1.5",
              }}
            />
          )
        ) : type === "text" ? (
          <input
            {...register}
            disabled={disabled}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className="flex-1 bg-transparent outline-none whitespace-pre-wrap break-words overflow-hidden"
            placeholder={placeholder}
            type={type ?? "text"}
            style={{ height: "auto", lineHeight: "1.5" }}
          />
        ) : type === "dropdown" ? (
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <select
                {...field}
                className="w-full outline-none bg-transparent"
                name="name"
                id="name"
              >
                <option value="" disabled>
                  {placeholder}
                </option>
                {dropdownList?.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            )}
          />
        ) : type === "date" ? (
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input className="w-full" type="date" placeholder={placeholder} />
            )}
          />
        ) : (
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input className="w-full" type="date" placeholder={placeholder} />
            )}
          />
        )}
        {renderRight}
      </div>
      {errors?.message && (
        <span className="whitespace-pre text-xs mt-2 text-orange-700">
          {errors?.message}
        </span>
      )}
    </div>
  );
};

export default CustomInput;
