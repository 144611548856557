import { default as _axios } from 'axios';
import setupInterceptor from './setupInterceptor';
import env from '../env';

const axios = _axios.create({
    baseURL: env.apiLink,
    timeout: 30000
});
setupInterceptor(axios);
export default axios;
