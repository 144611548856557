import env from './env';
const server = env.apiLink;

const api = {
  // auth
  login: `user/login`,
  signup: `user/signup`,
  verifyEmail: 'user/verify-email',
  resendOtpverifyEmail: 'user/resend-verify-email',

  //candidate
  getCandidates:'contact/get-candidates',
  addCandidate:'contact/add-candidate',
  updateCandidate:'contact/update-candidate',
  deleteCandidate:'contact/delete-candidate',


  token: `auth/token`,
  createRecruiterProfile: `recruiter-profile`,
  resetPassword: `auth/reset-password`,
  registerEmailVerify: `auth/email-verify`,

}

export default api;