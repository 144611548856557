
// auth token, logged
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const LOGIN_USER = 'LOGIN_USER';
export const AUTH_USER_UPDATE = 'AUTH_USER_UPDATE';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const TOKEN_REFRESHED = 'TOKEN_REFRESHED';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const SET_USER_DATA = 'SET_USER_DATA';



