import React from "react";
import dayjs from "dayjs";
import { candidateAssessmentList } from "./dummyData";

const CandidateAssessments = () => {
  const Assessment = ({ item, onClickAssessment }) => {
    return (
      <div
        className="flex items-center py-3.5 border rounded-md bg-[#FAFAFA] pl-3  hover:bg-white cursor-pointer mb-3"
        onClick={() => onClickAssessment && onClickAssessment(item)}
      >
        <label className="w-1/4 font-medium text-xs text-primary">
          {item.refId}
        </label>
        <label
          style={{ width: "30%" }}
          className="font-medium text-xs text-primary"
        >
          {item.title}
        </label>
        <label
          style={{ width: "30%" }}
          className="font-medium text-xs text-primary"
        >
          Recruiter {item.sender}
        </label>
        <label
          style={{ width: "30%" }}
          className="font-medium text-xs text-primary"
        >
          {item.status}
        </label>
        <label
          style={{ width: "20%" }}
          className="italic font-medium text-xs text-primary"
        >
          {dayjs(item.date).format("DD-MMM-YY")}
          <label className="font-medium text-xs text-primary pl-2">
            {dayjs(item.date).format("HH:mm")}
          </label>
        </label>
      </div>
    );
  };
  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <div className="flex flex-1 flex-col pt-2 max-h-fit overflow-y-hidden">
        <div className="flex items-center pb-3 pl-3">
          <label className="w-1/4 flex font-semibold text-xs text-primary">
            Reference
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Title
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Assessor
          </label>
          <label
            style={{ width: "30%" }}
            className="flex font-semibold text-xs text-primary"
          >
            Status
          </label>
          <div style={{ width: "20%" }} />
        </div>
        <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar">
          {candidateAssessmentList.map((item) => {
            return <Assessment key={item.refId} item={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default CandidateAssessments;
