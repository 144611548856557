import { useForm } from "react-hook-form";
import Button from "../../button/button";
import CustomInput from "../../CustomInput/CustomInput";
import { RowContainer } from "./Section.styles";
import { colors } from "../../../utils/theme";

const Section = ({ section, sectionSaved, onSave }) => {
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    defaultValues: { sectionTitle: section },
  });

  return (
    <div>
      <RowContainer>
        <CustomInput
          placeholder="Enter Section"
          bgColor="bg-transparent"
          inActiveOutlineColor={`border-[${colors.Border}]`}
          activeOutlineColor={`border-[${colors.PrimaryBlack}]`}
          value={section}
          className={`border`}
          register={{
            ...register("sectionTitle", {
              required: "This field is required.",
            }),
          }}
          disabled={sectionSaved}
          errors={errors["sectionTitle"]}
        />
        {!sectionSaved && (
          <Button
            variant="outline"
            color={isValid ? colors.PrimaryBlack : "#ECECEC"}
            disabled={!isValid}
            style={{ borderRadius: 4, maxHeight: 38 }}
            onClick={handleSubmit(onSave)}
          >
            Save
          </Button>
        )}
      </RowContainer>
    </div>
  );
};

export default Section;
