import store from '../store';
import authInterceptor from './authInterceptor';
import { removeToken } from '../store/actions/auth.action';
const setupInterceptor = (axios) => {
    authInterceptor(axios);
    
    const { dispatch } = store;
    const storedRefreshToken = localStorage.getItem('refreshToken');
    axios.interceptors.response.use(
        (res) => res,
        async (err) => {
            if (err.response) {
                const originalConfig = err.response.config;
                if (originalConfig.url !== '/user/login' && err.response) {
                    if (err.response.status === 401 && !!storedRefreshToken) {
                        // originalConfig._retry = true;
                        dispatch(removeToken());
                    }
                }
            }
            return Promise.reject(err);
        }
    );
};
export const setAuthToken = (axios, token) => {
    return new Promise((resolve, reject) => {
        try {
            axios.interceptors.request.use(
                (config) => {
                    config.headers['accessToken'] = token;
                    return config;
                },
                (err) => {
                    return Promise.reject(err);
                }
            );
            return resolve();
        } catch (err) {
            return reject(err);
        }
    });
};
export default setupInterceptor;
