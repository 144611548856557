import "twin.macro";
import tw, { styled } from "twin.macro";
import { colors } from "../../utils/theme";

export const QuestionContainer = styled.div(() => [
  tw`flex flex-1 flex-col border px-3 py-4 rounded-lg mt-2 gap-4`,
  `border-color:${colors.Border}`,
]);

export const AddSectionButton = styled.div(() => [
  tw`flex rounded py-0 px-6 gap-3 bg-[#F7F7F7] h-9 justify-center text-secondary items-center mt-9 mb-8 font-inter font-medium text-[13px] tracking-tight cursor-pointer`,
]);
