export const candidateAssessmentList = [
  {
    refId: "AB-1234AB",
    title: "C++ Assessment",
    status: "Assessment Received",
    sender: "John Doe",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234XZ",
    title: "React.js",
    sender: "Jane Smith",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234KL",
    title: "Node.js",
    sender: "Michael Lee",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234MN",
    title: "MongoDB",
    sender: "Sarah Johnson",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234AD",
    title: "C Language",
    sender: "David Brown",
    status: "Assessment Received",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1234GH",
    title: "C++ Assessment",
    sender: "Emily Davis",
    status: "Assessment Received",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1234AB",
    title: "Node.js",
    sender: "John Doe",
    status: "Completed Assessment",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1234GH",
    title: "React Native",
    sender: "Jane Smith",
    status: "Completed Assessment",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1236GH",
    title: "MongoDB",
    sender: "Sarah Johnson",
    status: "Assessment Received",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1234AB",
    title: "C++ Assessment",
    status: "Assessment Received",
    sender: "John Doe",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234XZ",
    title: "React.js",
    sender: "Jane Smith",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234KL",
    title: "Node.js",
    sender: "Michael Lee",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234MN",
    title: "MongoDB",
    sender: "Sarah Johnson",
    status: "Completed Assessment",
    date: new Date(),
    read: false,
  },
  {
    refId: "AB-1234AD",
    title: "C Language",
    sender: "David Brown",
    status: "Assessment Received",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1234GH",
    title: "C++ Assessment",
    sender: "Emily Davis",
    status: "Assessment Received",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1234AB",
    title: "Node.js",
    sender: "John Doe",
    status: "Completed Assessment",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1234GH",
    title: "React Native",
    sender: "Jane Smith",
    status: "Completed Assessment",
    date: new Date(),
    read: true,
  },
  {
    refId: "AB-1236GH",
    title: "MongoDB",
    sender: "Sarah Johnson",
    status: "Assessment Received",
    date: new Date(),
    read: true,
  },
];
